<template>
  <v-col>
    <form-creation-form @created="addToList"/>
    <v-card class="mt-4" flat tile :loading="fetchMixin.state === fetchMixin.STATES.LOADING">
      <v-card-title>
        <div>{{ $t('pages.form_info.title_s') }}</div>
      </v-card-title>

      <div id="info-button">
        <v-btn icon color="info">
          <v-icon>mdi-information</v-icon>
        </v-btn>
        {{ $t('common.words.buttons') }}
        <div id="tooltip-button">
          <div style="display: flex; flex-direction: column">
            <div>
              <v-btn icon color="primary">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <span>{{ $t('pages.form_info.buttons.modify') }}</span>
            </div>
            <div>
              <v-btn icon color="primary">
                <v-icon>mdi-upload</v-icon>
              </v-btn>
              <span>{{ $t('pages.form_info.buttons.push', {username: this.$store.state.auth.user.username, email:this.$store.state.auth.user.email}) }}</span>
            </div>
            <div>
              <v-btn icon color="warning">
                <v-icon>mdi-upload-multiple</v-icon>
              </v-btn>
              <span>{{ $t('pages.form_info.buttons.push_all') }}</span>
            </div>
            <div>
              <v-btn icon color="error">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
              <span>{{ $t('pages.form_info.buttons.delete') }}</span>
            </div>
          </div>
        </div>
      </div>

      <v-card-text v-if="fetchMixin.state === fetchMixin.STATES.ERROR">
        <div>{{ $t('errors.basic') }}</div>
      </v-card-text>

      <v-card-text v-if="forms.length === 0">
        Aucun questionaire disponible!
      </v-card-text>

      <v-card-text v-else-if="fetchMixin.state === fetchMixin.STATES.IDLE
      || fetchMixin.state === fetchMixin.STATES.LOADING">
        <v-simple-table>
          <thead>
          <tr>
            <th class="text-center">{{ $t('common.labels.id') }}</th>
            <th class="text-left" width="60%">{{ $t('common.labels.name') }}</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(form, i) in forms" :key="form.id">
            <td class="text-center">{{ form.id }}</td>
            <td class="text-left">{{ form.label }}</td>
            <td class="text-right">
              <v-btn icon color="primary"
                     @click.stop="editForm(i)"
                     :loading="fetchMixin.state === fetchMixin.STATES.LOADING"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>

              <!-- Push form -->
              <confirm-button width="400px" @click="pushForm(form.id)">
                <template v-slot:button="{ on, attrs }">
                  <v-btn
                    icon color="primary"
                    v-on="on" v-bind="attrs"
                  >
                    <v-icon>mdi-upload</v-icon>
                  </v-btn>
                </template>

                <template v-slot:title>
                  {{ $t('pages.form_info.push_form_1') }}<br/>
                  {{ $t('pages.form_info.push_form_2') }}
                </template>

                <template v-slot:message>
                  {{ $t('pages.form_info.push_form_3') }}
                </template>
              </confirm-button>

              <!-- Push form -->
              <confirm-button width="400px" @click="pushFormForAll(form.id)">
                <template v-slot:button="{ on, attrs }">
                  <v-btn
                    icon color="warning"
                    v-on="on" v-bind="attrs"
                  >
                    <v-icon>mdi-upload-multiple</v-icon>
                  </v-btn>
                </template>

                <template v-slot:title>
                  {{ $t('pages.form_info.push_form_1') }}<br/>
                  {{ $t('pages.form_info.push_form_2') }}
                </template>

                <template v-slot:message>
                  {{ $t('pages.form_info.push_all_form_3') }}
                </template>
              </confirm-button>

              <confirm-button width="400px" @click="remove(form.id)">
                <template v-slot:button="{ on, attrs }">
                  <v-btn
                    icon color="error"
                    v-on="on" v-bind="attrs"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>

                <template v-slot:title>
                  {{ $t('pages.form_info.delete_form_1') }}<br/>
                  {{ $t('pages.form_info.delete_form_2') }}
                </template>

                <template v-slot:message>
                  {{ $t('pages.form_info.delete_form_3') }}
                </template>
              </confirm-button>
            </td>
          </tr>
          </tbody>
        </v-simple-table>

        <paginator
          v-if="fetchMixin.pagination"
          :pagination="fetchMixin.pagination"
          @update="updatePagination"
        />
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
import FormCreationForm from '@/components/forms/creation/FormSaveForm.vue';
import FormService from '@/services/api/form.service';
import Paginator from '@/components/util/paginator.vue';

import FetchMixin from '@/mixins/fech.mixin';
import DeleteMixin from '@/mixins/delete.mixin';
import Utils from '@/utils/utils';
import ConfirmButton from '@/components/util/ConfirmButton.vue';
import FlashUtils from '@/utils/flash.utils';

export default {
  name: 'forms',
  mixins: [FetchMixin, DeleteMixin],
  components: {
    FormCreationForm,
    ConfirmButton,
    Paginator,
  },
  data: () => ({
    forms: [],
  }),
  methods: {
    fetchForms() {
      const { page, size } = this.fetchMixin.pagination || { page: 1, size: 20 };
      Utils.scrollToTop();
      return FormService.fetchForms({ page, size });
    },
    addToList(form) {
      this.forms = [...this.forms, form];
    },
    removeFromList(id) {
      this.forms = this.forms.filter((form) => form.id !== id);
    },
    editForm(i) {
      const formInfo = this.forms[i];
      this.$router.push({ name: 'form-info', params: { formId: formInfo.id, formInfo } });
    },
    async pushFormForAll(id) {
      try {
        await FormService.pushFormForAll(id);
        FlashUtils.sendSuccess('Le formulaire a été envoyé avec succès.');
      } catch (error) {
        FlashUtils.sendError("L'envoi du formulaire a échoué.");
      }
    },
    async pushForm(id) {
      try {
        await FormService.pushForm(id);
        FlashUtils.sendSuccess('Le formulaire a été envoyé avec succès.');
      } catch (error) {
        FlashUtils.sendError("L'envoi du formulaire a échoué.");
      }
    },
    deleteForm(id) {
      return FormService.deleteForm(id);
    },
  },
  created() {
    this.fetchMixin.config = {
      dataField: 'forms',
      fetch: 'fetchForms',
    };
    this.deleteMixin.config = {
      delete: 'deleteForm',
      callback: 'removeFromList',
    };
    this.fetch();
  },
};
</script>
<style scoped>
#info-button {
  width: fit-content;
}

#tooltip-button {
  display: none;
  background-color: rgba(255, 255, 255);
  padding: 5px 16px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  position: fixed;
}

#info-button:hover #tooltip-button {
  display: block;
}
</style>
